<template>
  <div>
    <b-row class="content-header mb-2">
      <!-- Content Left -->
      <b-col class="content-header-left" cols="12" md="6">
        <breadcrumb />
      </b-col>

      <!-- Content Right -->
      <!-- <b-col class="content-header-right text-md-right" md="3" cols="12">
        <b-button
          @click="showFormModal2(null)"
          class="px-3 text-capitalize"
          variant="primary"
        >
          <feather-icon size="16" icon="PlusIcon" class="mr-50" />
          ایجاد پوستر
        </b-button>
      </b-col> -->
      <b-col class="content-header-right text-md-right" md="3" cols="12">
        <b-button
          @click="showFormModal(null)"
          class="px-3 text-capitalize"
          variant="primary"
        >
          <feather-icon size="16" icon="PlusIcon" class="mr-50" />
          ایجاد اسلایدر
        </b-button>
      </b-col>
    </b-row>
    <div v-if="sliders">
      <div class="d-flex justify-content-between align-items-center">
        <per-page class="col-sm-12 col-md-3 col-lg-2" v-model="perPage" />
        <!-- <search
          ref="slidersSearchComponent"
          class="w-50"
          v-model="searchInput"
        /> -->
      </div>

      <b-card body-class="p-0" class="mt-1">
        <table-head
          ref="slidersTableHeadComponent"
          :items="tableHeadings"
          @sort="sort"
        />
        <b-row
          class="py-1 mx-1 border-bottom"
          v-for="slider in sliders"
          :key="slider.id"
        >
          <b-col class="d-flex align-items-center pl-0">
            <img
              style="border-radius: 15px; width: 100%"
              :src="$http.defaults.fileUrl + slider.desktop_image.path"
            />
          </b-col>
          <b-col
            class="d-flex align-items-center flex-row-reverse"
            style="direction: ltr"
          >
            {{ slider.url }}
          </b-col>
          <b-col class="d-flex align-items-center pl-0">
            {{ slider.name }}
          </b-col>
          <b-col class="d-flex align-items-center pl-0">
            {{
              new Date(slider.created_at).toLocaleString("fa-IR", {
                timeZone: "Iran",
              })
            }}
          </b-col>
          <b-col class="d-flex align-items-center pl-0">
            {{ slider.poster == 0 ? "خانه" : "" }}
            {{ slider.poster == 1 ? "الهام بخش برای سفرهای بعدی شما" : "" }}
            {{ slider.poster == 2 ? "اقامت در روم توریان" : "" }}
            {{
              slider.poster == 3
                ? "تورهای فراموش نشدنی که توسط مردم محلی برگزار می شود"
                : ""
            }}
          </b-col>
          <b-col class="d-flex align-items-center pl-0" :class="'text-primary'">
            {{ "اسلایدر" }}
          </b-col>
          <b-col
            class="d-flex align-items-center pl-0"
            :class="slider.status != 'active' ? 'text-danger' : 'text-success'"
          >
            {{ slider.status == "active" ? "" : "غیر" }}فعال
          </b-col>

          <b-col class="d-flex align-items-center">
            <b-button
              @click="showConfirmModal(slider)"
              variant="flat-danger"
              class="font-weight-bolder"
            >
              <feather-icon icon="Trash2Icon" size="20" />
            </b-button>
            <b-button
              @click="showFormModal(slider)"
              variant="flat-secondary"
              class="font-weight-bolder"
            >
              <feather-icon icon="Edit2Icon" size="20" />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <pagination
        ref="slidersPaginationComponent"
        v-model="page"
        :paginationData="paginationData2"
      />
    </div>
    <b-sidebar
      width="500px"
      v-model="formModal"
      right
      backdrop
      shadow
      bg-variant="white"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
              style="background-color: rgba(75, 75, 75, 0.12)"
              @click="()=>{formModal = false , errorMessage={} }"
              variant="flat-dark"
              class="btn-icon rounded-circle mr-1"
            >
              <feather-icon icon="XIcon" size="20" />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
            <h3 class="text-capitalize">
              {{ editMode ? "ویرایش اسلایدر" : "ایجاد اسلایدر" }}
            </h3>
          </div>
        </div>
      </template>
      <b-container class="">
        <b-row>
          <b-form-group class="mt-1 col-md-6">
            <label for="city_province" class="text-capitalize"> استان :</label>
            <v-select
              id="city_province"
              :components="{ OpenIndicator }"
              class="bg-white"
              :clearable="false"
              v-model="sliderInput.state_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :reduce="(province) => province.id"
              :options="provinces"
              placeholder="برای جستجو تایپ کنید"
              @input="getSelectedProvincesCity"
            />
            <ErrorMessage
              errorType="state"
              :errorMessages="errorMessage"
            ></ErrorMessage>
          </b-form-group>
          <b-form-group class="mt-1 col-md-6">
            <label for="city_province" class="text-capitalize"> شهر :</label>
            <v-select
              :loading="showGettingCityLoading"
              id="city_province"
              :components="{ OpenIndicator }"
              class="bg-white"
              :clearable="false"
              v-model="sliderInput.city_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :reduce="(city) => city.id"
              :options="cities"
              placeholder="برای جستجو تایپ کنید"
            />
            <ErrorMessage
              errorType="city"
              :errorMessages="errorMessage"
            ></ErrorMessage>
          </b-form-group>
        </b-row>
        <b-form-group class="mt-1">
          <label for="name" class="text-capitalize">عنوان : </label>
          <b-form-input @input="removeErrors('name')" id="name" type="text" v-model="sliderInput.name" />
          <ErrorMessage
            errorType="name"
            :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="">
          <label for="name" class="text-capitalize">لینک : </label>
          <b-form-input placeholder="https://admin-roomtoor.iran.liara.run/" id="name" type="text" v-model="sliderInput.url" @input="removeErrors('url')" />
          <ErrorMessage
            errorType="url"
            :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="">
          <label for="slug" class="text-capitalize">slug : </label>
          <b-form-input id="slug" type="text" v-model="sliderInput.slug" @input="removeErrors('slug')" />
          <ErrorMessage
            errorType="slug"
            :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="mt-1 col-md-6" v-slot="{ ariaDescribedby }">
          <label for="province_status" class="text-capitalize"> وضعیت : </label>
          <b-form-checkbox
            v-model="sliderInput.status"
            :aria-describedby="ariaDescribedby"
            value="active"
            @input="removeErrors('status')"
            >فعال</b-form-checkbox
          >
          <b-form-checkbox
            v-model="sliderInput.status"
            :aria-describedby="ariaDescribedby"
            value="inactive"
            @input="removeErrors('status')"
            >پیش نویس</b-form-checkbox
          >
          <ErrorMessage
            errorType="status"
            :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group label="نمایش در :" v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            id="checkbox-group-2"
            v-model="sliderInput.categories"
            :aria-describedby="ariaDescribedby"
            name="flavour-2"
            @input="removeErrors('categories')"
          >
            <!-- <template v-if="editMode">
          <b-form-checkbox :value="item" v-for="(item,index) in sliderInput.categories" :key="index">{{item.name=='home'?'صفحه اصلی':'صفحه شهر'}}</b-form-checkbox>
           </template> -->
            <!-- <template v-if="!editMode"> -->
            <b-form-checkbox :value="1"
              >صفحه اصلی</b-form-checkbox
            >
            <b-form-checkbox :value="2"
              >صفحه شهر</b-form-checkbox
            >
            <!-- </template> -->
          </b-form-checkbox-group>
          <ErrorMessage
            errorType="categories"
            :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="">
          <label for="desktop_image" class="text-capitalize"
            >desktop آپلود تصویر (600*1500):
          </label>
          <FilePicker
            @inputFile="inputDesktopImageFile"
            :url="desktopImageUrl"
            ref="desktopFilePicker"
          ></FilePicker>
          <!-- <b-form-file
            placeholder="desktop آپلود تصویر (600*1500)"
            v-model="sliderInput.desktop_image"
            id="desktop_imageSlider"
            accept=".jpg, .jpeg"
            :state="sliderInput.desktop_image != null"
          />
          <b-form-invalid-feedback v-if="sliderInput.desktop_image == null">
            تصویر را آپلود نمایید
          </b-form-invalid-feedback> -->
          <ErrorMessage
            errorType="desktop_image"
            :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="">
          <label for="desktop_mobile" class="text-capitalize"
            >Mobile آپلود تصویر (600*1500):
          </label>
          <FilePicker
            @inputFile="inputMobileImageFile"
            :url="mobileImageUrl"
            ref="mobileFilePicker"
          ></FilePicker>
          <!--          
          <b-form-invalid-feedback v-if="sliderInput.desktop_mobile == null">
            تصویر را آپلود نمایید
          </b-form-invalid-feedback> -->
          <ErrorMessage
            errorType="desktop_mobile"
            :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
      </b-container>
      <template #footer>
        <div class="d-flex align-items-center justify-content-end px-2 py-1">
          <b-button
            class="text-capitalize mr-2"
           @click="()=>{formModal = false , errorMessage={} }"
            variant="outline-dark"
          >
            {{ $t("cancel") }}
          </b-button>
          <b-button
            variant="primary"
            @click="submitForm()"
            :disabled="
              sliderInput.name == '' &&
              sliderInput.url == '' &&
              sliderInput.desktop_image == null &&
              sliderInput.desktop_mobile == null
            "
          >
            <div v-if="submitLoading" class="px-1">
              <b-spinner small style="width: 1.3rem; height: 1.3rem" />
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else class="text-capitalize">
              {{ $t("submit") }}
            </span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
    <!-- _______________________________________________________________________ -->
    <!-- <b-sidebar
      width="500px"
      v-model="formModal2"
      right
      backdrop
      shadow
      bg-variant="white"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
              style="background-color: rgba(75, 75, 75, 0.12)"
              @click="formModal2 = false"
              variant="flat-dark"
              class="btn-icon rounded-circle mr-1"
            >
              <feather-icon icon="XIcon" size="20" />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
            <h3 class="text-capitalize">
              {{ editMode ? "ویرایش پوستر" : "ایجاد پوستر" }}
            </h3>
          </div>
        </div>
      </template>
      <b-container class="">
        <b-form-group class="mt-3">
          <label for="name" class="text-capitalize">عنوان : </label>
          <b-form-input
            id="name"
            type="text"
            :state="sliderInput.name != ''"
            v-model="sliderInput.name"
          />
          <b-form-invalid-feedback v-if="sliderInput.name == ''">
            عنوان را مشخص کنید
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="">
          <label for="name" class="text-capitalize">لینک : </label>
          <b-form-input
            id="name"
            type="text"
            :state="sliderInput.url != ''"
            v-model="sliderInput.url"
          />
          <b-form-invalid-feedback v-if="sliderInput.url == ''">
            لینک را باید وارد کنید
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="">
          <label for="desktop_image" class="text-capitalize"
            >desktop آپلود تصویر (600*1500):
          </label>
          <b-form-file
            placeholder="desktop آپلود تصویر (600*1500)"
            v-model="sliderInput.desktop_image"
            accept=".jpg, .jpeg"
            :state="sliderInput.desktop_image != null"
          />
          <b-form-invalid-feedback v-if="sliderInput.desktop_image == null">
            تصویر را آپلود نمایید
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="">
          <label for="desktop_mobile" class="text-capitalize"
            >Mobile آپلود تصویر (720*720):
          </label>
          <b-form-file
            ref="refs"
            placeholder="Mobile آپلود تصویر (600*1500)"
            v-model="sliderInput.desktop_mobile"
            accept=".jpg, .jpeg"
            :state="sliderInput.desktop_mobile != null"
          />
          <b-form-invalid-feedback v-if="sliderInput.desktop_mobile == null">
            تصویر را آپلود نمایید
          </b-form-invalid-feedback>
        </b-form-group>
      </b-container>
      <template #footer>
        <div class="d-flex align-items-center justify-content-end px-2 py-1">
          <b-button
            class="text-capitalize mr-2"
            @click="formModal = false"
            variant="outline-dark"
          >
            {{ $t("cancel") }}
          </b-button>
          <b-button
            variant="primary"
            @click="submitForm()"
            :disabled="
              sliderInput.name == '' &&
              sliderInput.url == '' &&
              sliderInput.desktop_image == null &&
              sliderInput.desktop_mobile == null
            "
          >
            <div v-if="submitLoading" class="px-1">
              <b-spinner small style="width: 1.3rem; height: 1.3rem" />
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else class="text-capitalize">
              {{ $t("submit") }}
            </span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
    _______________________________________________________________________ -->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BCard,
  BAvatar,
  BButton,
  BSpinner,
  BSidebar,
  BContainer,
  BFormTextarea,
  BFormInvalidFeedback,
  BFormRadio,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import PerPage from "@/components/PerPage.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import Search from "@/components/Search.vue";
import TableHead from "@/components/TableHead.vue";
import vSelect from "vue-select";
import FilePicker from "@/components/FilePicker.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";

export default {
  components: {
    BRow,
    BCol,
    PerPage,
    BFormGroup,
    BFormInvalidFeedback,
    BFormInput,
    BFormFile,
    BCard,
    BAvatar,
    BButton,
    Breadcrumb,
    Pagination,
    Search,
    TableHead,
    BSpinner,
    BSidebar,
    BContainer,
    BFormTextarea,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormCheckboxGroup,
    FilePicker,
    ErrorMessage,
  },
  data() {
    return {
      errorMessage: {},
      mobileImageUrl: "",
      desktopImageUrl: "",
      showGettingCityLoading: false,
      categories: [],
      perPage: 10,
      page: 1,
      provinces: [],
      cities: [],
      sliders: null,
      defaultAvatar: require("@/assets/images/avatars/default.png"),
      cancelToken: undefined,
      sortBy: "",
      sortDir: "",
      searchInput: "",
      paginationData: null,
      paginationData2: null,
      sliderInput: {
        state_id: "",
        city_id: "",
        name: "",
        slug: "",
        url: "",
        status: "",
        categories: [],
        desktop_image: null,
        desktop_mobile: null,
      },
      tableHeadings: [
        {
          title: "عکس",
        },
        {
          title: "لینک",
          slug: "link",
          sortable: true,
        },
        {
          title: "عنوان",
          slug: "title",
          sortable: true,
        },
        {
          title: "تاریخ انتشار",
          slug: "created_at",
          sortable: true,
        },
        {
          title: "بخش مربوطه",
          slug: "poster",
          sortable: true,
        },
        {
          title: "نوع",
          slug: "poster",
          sortable: true,
        },
        {
          title: "وضعیت",
          slug: "deleted_at",
          sortable: true,
        },
        {
          title: "ابزارها",
        },
      ],

      OpenIndicator: {
        render: (createElement) =>
          createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
      },
      companies: [],
      provincesCancelToken: undefined,
      sliderInput: {
        id: "",
        state_id: "",
        name: "",
      },
      editMode: false,
      formModal: false,
      formModal2: false,
      submitLoading: false,
    };
  },
  mounted() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
    this.getSliders();
    this.getProvinces();
  },
  watch: {
    searchInput(value) {
      this.page = 1;
      this.getSliders();
    },
    perPage(value) {
      this.page = 1;
      this.getSliders();
    },
    page(value) {
      this.getSliders();
    },
  },
  methods: {
    removeErrors(data){
      
      if(typeof this.errorMessage[data] !== 'undefined' && this.errorMessage[data].length>0){
        // this.errorMessage[data][0]="salllam"
        delete this.errorMessage[data]
        this.errorMessage = Object.assign({}, this.errorMessage, this.errorMessage)
        console.log(data,this.errorMessage)
      }
    },
    inputDesktopImageFile(file) {
      this.removeErrors('desktop_image')
      this.sliderInput.desktop_image = file;
    },
    inputMobileImageFile(file) {
      this.sliderInput.desktop_mobile = file;
      this.removeErrors('desktop_mobile')
    },
    getSelectedProvincesCity(provinceId) {
      this.showGettingCityLoading = true;
      this.getCityByProvinceId(provinceId);
    },
    async getCityByProvinceId(provinceId) {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        // search: this.searchInput,
        // per_page: this.perPage,
        // page: this.page,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: `/admin/cities/${provinceId}`,
        params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data;
          this.cities = data.data;
          this.paginationData = data.meta;
          this.showGettingCityLoading = false;
          this.sliderInput.city_id = this.cities[0]["id"];
        })
        .catch((err) => {
          console.log(err);
          this.showGettingCityLoading = false;
        });
    },
    getProvinces(search, loading) {
      if (typeof this.provincesCancelToken != typeof undefined) {
        this.provincesCancelToken.cancel(
          "Operation canceled due to new request."
        );
      }
      //Save the cancel token for the current request
      this.provincesCancelToken = this.$http.CancelToken.source();

      let data = {
        paginate: false,
        //  this.searchInput,
        // per_page: this.perPage,
        // page: this.page,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: "/admin/states",
        params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data;
          this.provinces = data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sort(args) {
      this.page = 1;
      this.sortBy = args.sortBy;
      this.sortDir = args.sortDir;
      this.getSliders();
    },
    //_________________________________________________________________________
    async getSliders(THIS = this) {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        paginate: true,
        per_page: this.perPage,
        page: this.page,

        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
        // host: window.location.href.replace('3000', '8080').replace('8081', '8080'),
      };

      const requestData = {
        method: "get",
        url: "/admin/sliders",

        // params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          console.log(response.data.data);
          THIS.sliders = response.data.data;
          this.paginationData2 = data.meta;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //_____________________________________________________________________________
    showConfirmModal(slider) {
      this.$bvModal
        .msgBoxConfirm(`آیا از حذف اسلایدر مطمئن هستید ؟  : ${slider.name}.`, {
          title: this.$t("Please Confirm"),
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (confirmed) => {
          if (confirmed) {
            let sliderIndex = 0;
            this.sliders = await this.sliders.filter((sliderItem, index) => {
              if (sliderItem.id == slider.id) {
                sliderIndex = index;
              }
              return sliderItem.id !== slider.id;
            });

            const requestData = {
              method: "delete",
              url: `/admin/sliders/${slider.id}`,
            };
            this.$http(requestData)
              .then((response) => {
                this.$bvToast.toast(`${slider.name} با موفقیت حذف شد.`, {
                  title: this.$t("Done"),
                  variant: "success",
                  solid: true,
                });
              })
              .catch((err) => {
                let message = this.$t("Unable to delete!");
                if (
                  err.response &&
                  err.response.data &&
                  err.response.data.message
                )
                  message = err.response.data.message;
                this.$bvToast.toast(message, {
                  title: this.$t("Error"),
                  variant: "danger",
                  solid: true,
                });
                this.sliders.splice(sliderIndex, 0, slider);
              });
          }
        });
    },
    //__________________________________________________________________________________
    showFormModal(slider) {
      if (slider) {
        this.sliderInput = {
          id: slider.id,
          state_id: slider.state.id,
          name: slider.name,
          city_id: slider.city.id,
          slug: slider.slug,
          url: slider.url,
          status: slider.status,
          categories: slider.categories,
          desktop_image: null,
          desktop_mobile: null,
        };
          this.$refs.desktopFilePicker.clearFileInput()
          this.$refs.mobileFilePicker.clearFileInput()
        this.mobileImageUrl = slider.desktop_mobile.path;
        this.desktopImageUrl = slider.desktop_image.path;
        this.getCityByProvinceId(slider.state.id);
        this.editMode = true;
      } else {
        this.sliderInput = {
          id: "",
          state_id: "",
          name: "",
          city_id: "",
          slug: "",
          url: "",
          status: "",
          categories: [],
          desktop_image: null,
          desktop_mobile: null,
        };
        this.editMode = false;
          this.$refs.desktopFilePicker.clearFileInput()
          this.$refs.mobileFilePicker.clearFileInput()
        this.mobileImageUrl = "";
        this.desktopImageUrl = "";
      }
      this.formModal = true;
    },
    //________________________________________________________________
    showFormModal2(slider) {
      if (slider) {
        this.sliderInput = {
          id: slider.id,
          state_id: slider.province.id,
          name: slider.name,
        };
        this.editMode = true;
      } else {
        this.sliderInput = {
          id: "",
          state_id: "",
          name: "",
        };
        this.editMode = false;
      }
      this.formModal2 = true;
    },
    //________________________________________________________________
    submitForm() {
      this.submitLoading = true;

      let formData = new FormData();
      if (this.sliderInput.city_id) {
        formData.append("city_id", this.sliderInput.city_id);
      }
      if (this.sliderInput.name) {
        formData.append("name", this.sliderInput.name);
      }
      if (this.sliderInput.slug) {
        formData.append("slug", this.sliderInput.slug);
      }
      if (this.sliderInput.status) {
        formData.append("status", this.sliderInput.status);
      }
      if (this.sliderInput.desktop_image) {
        formData.append("desktop_image", this.sliderInput.desktop_image);
      }
      if (this.sliderInput.desktop_mobile) {
        formData.append("desktop_mobile", this.sliderInput.desktop_mobile);
      }
      if (this.sliderInput.url) {
        formData.append("url", this.sliderInput.url);
      }
      if (this.sliderInput.categories.length > 0) {
        this.sliderInput.categories.forEach((category, index) => {
          formData.append(`categories[${index}]`, category);
        });
      }

      const requestData = {
        method: this.editMode ? "post" : "post",
        url: this.editMode
          ? `/admin/sliders/update/${this.sliderInput.id}`
          : `/admin/sliders`,
        data: formData,
      };

      this.$http(requestData)
        .then((response) => {
          // this.uploadFile("desktop_imageSlider", "d_" + response.data.data);
          // this.uploadFile("desktop_mobileSlider", "m_" + response.data.data);
          let message = "با موفقیت اضافه شد";
          if (this.editMode) message = "با موفقیت ویرایش شد";
          this.$bvToast.toast(message, {
            title: this.$t("Done"),
            variant: "success",
            solid: true,
          });
          if (!this.editMode) {
            this.page = 1;
            this.searchInput = "";
            this.sortBy = "";
            this.sortDir = "";
          }
          this.getSliders();
          this.submitLoading = false;
          this.formModal = false;
        })
        .catch((err) => {
          console.log(err);
          let message = "خطا در ایجاد";
          if (this.editMode) message = "خطا در ویرایش";
          if (
            err.response &&
            err.response.data &&
            err.response.data.response &&
            err.response.data.response.message
          )
            message = err.response.data.response.message;
          if (
            err.response &&
            err.response.data &&
            err.response.data.response &&
            err.response.data.response.meta &&
            err.response.data.response.meta.errors
          )
            this.errorMessage = err.response.data.response.meta.errors;
          console.log("this.errorMessage", this.errorMessage);

          this.$bvToast.toast(message, {
            title: this.$t("Error"),
            variant: "danger",
          });
          this.submitLoading = false;
          console.log(err);
        });
      // this.uploadFile("desktop_imageSlider", "d_");
    },
    //________________________________________________________________
    async uploadFile(idInput = "", imgName = "", THIS = this) {
      var formData = new FormData();
      // formData.append("file", document.getElementById(id).files[0]);
      formData.append("file", document.getElementById(idInput).files[0]);
      formData.append("format", ["jpg", "jpeg"]);
      formData.append("idImg", imgName);
      var xhttp = new XMLHttpRequest();
      xhttp.open(
        "POST",
        window.location.origin.replace(":8081", ":8080") +
          "/api_RoomToor/ajaxfile.php",
        true
      );
      console.log(xhttp);
      xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          this.getVar = JSON.parse(this.responseText);
          if (this.getVar.response == 1) {
            THIS.submitLoading = false;
            THIS.formModal = false;
            THIS.getSlider();
          } else {
            // THIS.error(THIS, "فرمت فایل باید jpg باشد");
          }
        }
      };
      xhttp.send(formData);
      // setTimeout(function() {
      // 	THIS.$router.push(url);
      // }, 1000)
    },
    //________________________________________________________________
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
